<template>
  <v-select
    class="select-profile"
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    v-bind="$attrs"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Profile
 * ==================================================================================
 **/

import bus from '@/store/modules/bus'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { find, findIndex } from 'lodash'

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select building',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    requiredBuilding: {
      type: Boolean,
      default: false,
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },

    profileId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      fetching: false,
      profiles: [],
    }
  },

  computed: {
    ...mapGetters({
      // profileId: 'filter/profileId',
    }),

    validItems() {
      let items = [
        ...this.items,
        ...(this.profiles && this.profiles.length ? this.profiles : []),
      ]

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }

    bus.$on('profileCreated', this.addProfile)
    bus.$on('profileUpdated', this.updateProfile)
    bus.$on('profileRemoved', this.removeProfile)
  },

  watch: {
    buildingId(newValue, oldValue) {
      newValue !== oldValue && this.fetch()
    },
  },

  methods: {
    ...mapActions({
      getProfiles: 'profile/getProfiles',
      getProfilesByBuildingId: 'profile/getProfilesByBuildingId',
    }),

    ...mapMutations({
      clearProfiles: 'profile/clearProfileList',
    }),

    async fetch() {
      this.fetching = true
      this.clearProfiles()

      if (this.requiredBuilding) {
        if (this.buildingId) {
          await this.getProfilesByBuildingId(this.buildingId)
            .then((data) => {
              this.profiles = data

              if (this.preSelect && !this.profileId && this.validItems.length) {
                this.onChange(this.validItems[0][this.valueColumn])
              } else if (!this.value && this.profileId) {
                this.onChange(this.profileId)
              }

              this.$emit('populated', this.profiles)
            })
            .finally(() => {
              this.fetching = false
            })
        }
      } else {
        await this.getProfiles()
          .then((data) => {
            this.profiles = data

            if (this.preSelect && !this.profileId && this.validItems.length) {
              this.onChange(this.validItems[0][this.valueColumn])
            } else if (!this.value && this.profileId) {
              this.onChange(this.profileId)
            }

            this.$emit('populated', this.profiles)
          })
          .finally(() => {
            this.fetching = false
          })
      }

      this.fetching = false
    },

    onChange(value) {
      this.$emit('input', value)
      this.$emit(
        'change-profile-access',
        this.validItems.find((item) => item.id === value)
      )
    },

    addProfile(profile) {
      const exist = find(this.profiles, { id: profile.id })
      if (!exist) {
        this.profiles.push(profile)
      }
    },

    updateProfile(profile) {
      const index = findIndex(this.profiles, { id: profile.id })
      if (index) {
        this.$set(this.profiles, index, profile)
      }
    },

    removeProfile(profile) {
      const index = findIndex(this.profiles, { id: profile.id })
      if (index) {
        this.profiles.splice(index)
      }
    },
  },
}
</script>
