<template>
  <div class="team-form">
    <v-alert
      dense
      outlined
      type="warning"
      color="orange darken-3"
      max-width="400px"
      v-if="!!(team && team.blockedOrRemoved)"
    >
      This account is <strong>{{ team.blockedOrRemovedLabel }}</strong
      >.
    </v-alert>

    <v-form ref="form" class="team-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row class="padding-bottom-0">
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            placeholder="Full name"
            v-model="form.full_name"
            :error-messages="form.$getError('full_name')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Email</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.email"
            placeholder="Email address"
            :error-messages="form.$getError('email')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled"
          >
            <v-tooltip slot="append" top v-if="team && team.email">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on" v-if="team.email_verified">{{
                  icons.check
                }}</v-icon>
                <v-icon color="warning" v-on="on" v-else>{{
                  icons.alert
                }}</v-icon>
              </template>
              <span v-if="team.email_verified">Email Verified</span>
              <span v-else>Unverified email</span>
            </v-tooltip>
          </v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Phone</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            v-model="form.phone_number"
            placeholder="Phone"
            :required="isUpdate"
            :error-messages="form.$getError('phone_number')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled"
          >
            <v-tooltip slot="append" top v-if="team && team.phone_number">
              <template v-slot:activator="{ on }">
                <v-icon
                  color="success"
                  v-on="on"
                  v-if="team.phone_number_verified"
                  >{{ icons.check }}</v-icon
                >
                <v-icon color="warning" v-on="on" v-else>{{
                  icons.alert
                }}</v-icon>
              </template>
              <span v-if="team.phone_verified">Phone Number Verified</span>
              <span v-else>Unverified Phone Number</span>
            </v-tooltip>
          </v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">User Type</label>
          <SelectStatus
            flat
            solo
            required
            class="mt-2"
            v-model="form.role"
            :list="userTypes"
            placeholder="Select User Type"
            :error-messages="form.$getError('role')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled || !canModify"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building(s)</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy || setLoading"
            :disabled="form.$busy || isDisabled || disableBuildingField"
            :placeholder="disableBuildingField ? 'All' : 'Select building'"
            isHasSelectAll
          />

          <template>
            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label"> Set Password </label>
            <PasswordField
              flat
              solo
              required
              class="mt-2"
              placeholder="Password"
              v-model="form.password"
              :error-messages="form.$getError('password')"
              :loading="form.$busy"
              :disabled="form.$busy || isDisabled"
              autocomplete="off"
            ></PasswordField>
          </template>

          <template>
            <span class="red--text"> <strong>* </strong></span>
            <label class="text-field-label"> Confirm Password </label>
            <PasswordField
              flat
              solo
              required
              class="mt-2"
              placeholder="Confirm Password"
              v-model="form.password_confirmation"
              :error-messages="form.$getError('password_confirmation')"
              :loading="form.$busy"
              :disabled="form.$busy || isDisabled"
              autocomplete="off"
            ></PasswordField>
          </template>

          <label class="text-field-label" v-if="isUpdate"
            >Onboarding date</label
          >
          <v-text-field
            v-if="isUpdate"
            flat
            solo
            class="mt-2"
            placeholder="Onboarding Date"
            v-model="form.onboardingDate"
            :loading="form.$busy"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-checkbox
            v-if="team"
            :input-value="team.blocked_at"
            label="Disable team account"
            color="orange darken-3"
            class="mt-0"
            :disabled="team && !!team.deleted_at"
            @mousedown="showModal = true"
          ></v-checkbox>

          <div class="mt-4">
            <v-btn
              v-if="canModify"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy || loading"
              :disabled="isDisabled || loading"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmModal
      v-model="showModal"
      title="Disable team account"
      :message="disableMessage"
      @cancel="showModal = false"
      @confirm="blockAccount"
    ></ConfirmModal>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Team Form
 * ==================================================================================
 **/
import { mapState, mapActions, mapGetters } from 'vuex'
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { get, includes } from 'lodash'
import { isValidMobileNumber } from '@/utils/phone'
import PasswordField from '@/components/fields/PasswordField'
import SelectStatus from '@/components/fields/SelectStatus'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import USER_TYPES from '@/utils/enums/UserType'
import ROLE from '@/utils/enums/Role'
import { dateFormat } from '@/utils/date'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings'

export default {
  components: {
    PasswordField,
    SelectMultipleBuildings,
    SelectStatus,
    ConfirmModal,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    team: {
      type: Object,
      default: undefined,
    },
    avatarFile: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      form: new Form({
        full_name: '',
        email: '',
        phone_number: '',
        password: '',
        password_confirmation: '',
        building_id: [],
        role: USER_TYPES[0].value,
      }),
      showModal: false,
      icons: {
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      loading: false,
      disableBuildingField: false,
    }
  },

  computed: {
    ...mapState({
      registeredTeam: (state) => state.team.teamDetails,
      role: (state) => state.auth.role,
      user: (state) => state.auth.user,
    }),

    ...mapGetters({
      loggedInTeamId: 'auth/teamId',
    }),

    header() {
      return this.isUpdate ? 'Team Information' : 'Create Team Member'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    disableMessage() {
      if (!this.team) return ''

      const action = this.team.blocked_at ? 'unblock' : 'block'
      return `Are you sure you want ${action} this account?`
    },

    submitApi() {
      return this.isUpdate ? this.updateTeam : this.registerTeam
    },

    isDisabled() {
      return this.team ? !this.team.isUpdatable : false
    },

    isUpdate() {
      return !!this.team
    },

    isOwner() {
      return this.team ? this.loggedInTeamId === this.team.id : false
    },

    userTypes() {
      let list = [...USER_TYPES]
      if (this.canModify) {
        if (this.role !== ROLE.SUPER_ADMIN) {
          list = list.filter((item) => item.value !== ROLE.SUPER_ADMIN)

          if (this.role !== ROLE.ADMIN && this.role !== ROLE.ADMIN_AI) {
            list = list.filter(
              (item) =>
                item.value !== ROLE.ADMIN && item.value !== ROLE.ADMIN_AI
            )
          }

          return list
        }
      }

      return list.filter((item) => item.value !== ROLE.SUPER_ADMIN)
    },

    canModify() {
      if (this.team?.id) {
        switch (this.role) {
          case ROLE.SUPER_ADMIN:
            if (this.team.id === this.user.id) {
              return true
            } else {
              if (!includes(this.team.roles, ROLE.SUPER_ADMIN)) {
                return true
              }
            }

            return false
          case ROLE.ADMIN:
            if (this.team.id === this.user.id) {
              return true
            } else {
              if (
                !includes(this.team.roles, ROLE.SUPER_ADMIN) &&
                !includes(this.team.roles, ROLE.ADMIN) &&
                !includes(this.team.roles, ROLE.ADMIN_AI)
              ) {
                return true
              }
            }

            return false
          default:
            return false
        }
      } else {
        return includes(
          [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.ADMIN_AI],
          this.role
        )
      }
    },
  },

  watch: {
    user(newValue, oldValue) {
      this.initForm()
    },
    registeredTeam() {
      this.initForm()
    },
    'form.role'(newValue) {
      if (
        newValue === ROLE.ADMIN ||
        newValue === ROLE.SUPER_ADMIN ||
        newValue === ROLE.ADMIN_AI
      ) {
        this.disableBuildingField = true
        this.form.building_id = []
      } else this.disableBuildingField = false
    },
  },

  created() {
    this.initForm()
  },

  methods: {
    ...mapActions({
      registerTeam: 'team/registerTeam',
      updateTeam: 'team/updateTeam',
      blockTeam: 'team/blockTeam',
    }),

    initForm() {
      if (this.team) {
        const listBuildingId = this.team.buildings.map((item) => item.id)
        this.form.building_id = listBuildingId
        this.form.full_name = this.team.full_name
        this.form.email = this.team.email
        this.form.phone_number = this.team.phone_number
        this.form.role = get(this.team.roles, '0')
        this.form.onboardingDate = dateFormat(this.team.onboarded_at)
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      // Remove spaces from the phone_number field
      this.form.phone_number = this.form.phone_number.replace(/\s/g, '')

      let forUpdate = this.isUpdate
      console.log(this.getFormData())
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false

            this.form.password = null
            this.form.password_confirmation = null

            this.showSnackbar('Team details successfully updated!')
          } else {
            this.showSnackbar('Team successfully created!')
            this.$router.push({
              name: 'teams',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    blockAccount(value) {
      this.form.$busy = true
      const action = this.team.blocked_at ? 'unblocked' : 'blocked'
      this.blockTeam({
        id: this.team.id,
        action,
      })
        .then(() => {
          this.showModal = false
          this.showSnackbar(`Team successfully ${action}!`)
        })
        .catch((err) => {
          this.form.$setErrors(this.getValidationErrors(err))
        })
        .finally(() => {
          this.form.$busy = false
        })
    },

    /**
     * Validate form image values on create only
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (!this.form.phone_number) {
        this.form.$setError('phone_number', 'Phone Number is invalid.')
      }

      if (
        this.form.phone_number &&
        !isValidMobileNumber(this.form.phone_number)
      ) {
        this.form.$setError(
          'phone_number',
          'Phone number must be valid or in national format.'
        )
      }

      if (!this.form.full_name) {
        this.form.$setError('full_name', 'Full name is invalid.')
      }

      if (!this.form.email) {
        this.form.$setError('email', 'Email is invalid.')
      }

      // Validate password
      if (!this.isUpdate) {
        if (!this.form.password_confirmation) {
          this.form.$setError(
            'password_confirmation',
            'Confirm password is empty.'
          )
        }

        if (!this.form.password) {
          this.form.$setError('password', 'Password is empty.')
        } else {
          if (this.form.password.trim().length < 8) {
            this.form.$setError(
              'password',
              'The password must be at least 8 characters.'
            )
          } else {
            if (this.form.password !== this.form.password_confirmation) {
              this.form.$setError(
                'password_confirmation',
                'Confirm password is not match'
              )
            }
          }
        }
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.team.id

        // if (!this.isOwner) {
        //   delete form.password
        //   delete form.password_confirmation
        // }

        return form
      }
      if (this.form.building_id) {
        form.building_id = [...this.form.building_id]
      }
      let formData = new FormData()
      Object.keys(form).forEach((key) => {
        formData.append(key, form[key])
      })

      if (this.avatarFile) {
        // formData.append('avatar', this.avatarFile.file)
        form.avatar = this.avatarFile.file
      }
      return form
    },

    setLoading(status = true) {
      this.loading = status
    },
  },
}
</script>
<style lang="scss">
.team-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
